// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../lib/I18n.re.mjs";
import * as React from "react";
import * as Lingui from "../../locales/Lingui.re.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as NotFound from "../pages/NotFound.re.mjs";
import * as InfoAlert from "../molecules/InfoAlert.re.mjs";
import * as Localized from "./i18n/Localized.re.mjs";
import * as ReactIntl from "react-intl";
import * as Core__Option from "@rescript/core/src/Core__Option.re.mjs";
import * as React$1 from "@lingui/react";
import * as ReactRouterDom from "react-router-dom";
import * as JsxRuntime from "react/jsx-runtime";
import * as AppContext from "../layouts/appContext";

function parseLang(loc) {
  return loc.split("-")[0];
}

function parseLocale(loc) {
  return loc.split("-")[1];
}

var make = AppContext.LocaleContext.Provider;

var Provider = {
  make: make
};

var context = AppContext.LocaleContext;

var LocaleContext = {
  Provider: Provider,
  context: context
};

function LangProvider(props) {
  var data = ReactRouterDom.useLoaderData();
  var match = data.lang;
  var locale;
  switch (match) {
    case "en" :
        locale = "en";
        break;
    case "ja" :
        locale = "ja";
        break;
    default:
      locale = undefined;
  }
  return JsxRuntime.jsx(make, {
              children: JsxRuntime.jsx(React$1.I18nProvider, {
                    i18n: Lingui.i18n,
                    children: locale !== undefined ? JsxRuntime.jsx(ReactIntl.IntlProvider, {
                            locale: locale,
                            timeZone: data.timezone,
                            children: JsxRuntime.jsx(ReactRouterDom.Outlet, {})
                          }) : JsxRuntime.jsx(NotFound.make, {})
                  }),
              value: data
            });
}

function make$1(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  newrecord.to = props.to.startsWith("/") ? "/" + locale.lang + props.to : props.to;
  return JsxRuntime.jsx(ReactRouterDom.Link, newrecord);
}

var Link = {
  make: make$1
};

function make$2(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  newrecord.to = props.to.startsWith("/") ? "/" + locale.lang + props.to : props.to;
  return JsxRuntime.jsx(ReactRouterDom.NavLink, newrecord);
}

var NavLink = {
  make: make$2
};

function make$3(props) {
  var locale = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(props);
  var newrecord$1 = Caml_obj.obj_dup(props.to);
  newrecord$1.pathname = Core__Option.map(props.to.pathname, (function (pathname) {
          if (pathname.startsWith("/")) {
            return "/" + locale.lang + pathname;
          } else {
            return pathname;
          }
        }));
  newrecord.to = newrecord$1;
  return JsxRuntime.jsx(ReactRouterDom.Link, newrecord);
}

var LinkWithOpts = {
  make: make$3
};

function useNavigate(prim) {
  return ReactRouterDom.useNavigate();
}

function useLocation(prim) {
  return ReactRouterDom.useLocation();
}

var Router = {
  Link: Link,
  NavLink: NavLink,
  LinkWithOpts: LinkWithOpts,
  useNavigate: useNavigate,
  useLocation: useLocation
};

function LangProvider$DetectedLang(props) {
  var match = React.useState(function () {
        return false;
      });
  var setLangNotice = match[1];
  var match$1 = ReactRouterDom.useLocation();
  var pathname = match$1.pathname;
  var locale = React.useContext(context);
  var navigate = ReactRouterDom.useNavigate();
  React.useEffect((function () {
          console.log("Effect");
          Core__Option.map(locale.detectedLang, (function (detectedLang) {
                  if (detectedLang !== locale.lang) {
                    return Localized.loadMessages(detectedLang, Lingui.loadMessagesForDetected({
                                      ja: import("../../locales/src/components/pages/DefaultLayoutMap.re/ja"),
                                      en: import("../../locales/src/components/pages/DefaultLayoutMap.re/en")
                                    })).then(function (param) {
                                Lingui.detectedI18n.activate(detectedLang);
                                setLangNotice(function (param) {
                                      return true;
                                    });
                              });
                  } else {
                    setLangNotice(function (param) {
                          return false;
                        });
                    return Promise.resolve();
                  }
                }));
        }), [locale.lang]);
  return JsxRuntime.jsx("div", {
              children: match[0] ? JsxRuntime.jsx(InfoAlert.make, {
                      children: Lingui.detectedI18n._("/96Fb+"),
                      cta: Lingui.detectedI18n._("8hwlOf"),
                      ctaClick: (function () {
                          navigate(I18n.getLangPath(Core__Option.getOr(locale.detectedLang, "en")) + I18n.getBasePath(locale.lang, pathname), undefined);
                        })
                    }) : null,
              className: "mx-auto max-w-7xl mb-4"
            });
}

var DetectedLang = {
  make: LangProvider$DetectedLang
};

var make$4 = LangProvider;

export {
  parseLang ,
  parseLocale ,
  LocaleContext ,
  make$4 as make,
  Router ,
  DetectedLang ,
}
/* make Not a pure module */
